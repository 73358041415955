function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(function () {
    if (window.outerWidth <= 992) {
        let headerMobile = document.querySelector('.header--mobile'),
            filterWrapper = document.querySelector('.filter-block-wrapper'),
            filters = document.querySelector('.category-filter')
        if (filters) {
            filterWrapper.querySelector('#btnFilter').onclick = () => {
                console.log('123')
                filters.classList.add('active')
                document.body.classList.add('mobile-hidden')
            }

            filters.querySelector('.heading').onclick = () => {

                filters.classList.remove('active')
                document.body.classList.remove('mobile-hidden')
            }
        } else {
            filterWrapper.querySelector('#btnFilter').style.display = 'none'
        }
        if (filterWrapper) {
            let offset = Math.floor(filterWrapper.getBoundingClientRect().top + window.scrollY - 50)
            document.onscroll = customThrottle(function () {
                if (window.scrollY >= offset) {
                    filterWrapper.classList.add('fixed')
                    headerMobile.classList.remove('header-shadow')
                } else {
                    filterWrapper.classList.remove('fixed')
                    headerMobile.classList.add('header-shadow')
                }
            }, 50)
        }
    }


})

function customThrottle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) options = {};
    var later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };
    return function () {
        var now = Date.now();
        if (!previous && options.leading === false) previous = now;
        var remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
};